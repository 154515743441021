import React from "react";
import PropTypes from "prop-types";

import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Seo from "../components/Seo";

import Layout from "../components/Layout";
import { HTMLContent } from "../components/Content";

import FullWidthImage from "../components/FullWidthImage";
import DisplayContentHeader from "../components/DisplayContentHeader";

import Summary from "../components/Summary";
import DisplayGallery from "../components/DisplayGallery";
import { DiscussionEmbed } from "disqus-react";

// eslint-disable-next-line
export const GalleryPageTemplate = ({
  slug,
  title,
  subtitle,
  footer,
  images,
  author,
  creationDate,
  breadcrumbs,
  options,
  body,
  helmet,
  photos,
  summary,
  previewContent,
}) => {
  let heroImage, footerImage, smallImage;
  if (images && images.hero) heroImage = getImage(images.hero) || images.hero;
  if (images && images.footer)
    footerImage = getImage(images.footer) || images.footer;

  // eslint-disable-next-line
  if (images && images.smallImage) smallImage = images.smallImage;

  let showTitle = options.showTitle;
  let showSummary = options.showSummary;
  let showComments = options.showComments;

  if (!process.env.GATSBY_DISQUS_NAME) showComments = false;

  let disqusConfig;

  if (showComments) {
    disqusConfig = {
      shortname: process.env.GATSBY_DISQUS_NAME,
      config: { identifier: slug, title },
    };
  }

  //console.log(showSummary, summary);

  return (
    <div>
      {helmet || null}

      {heroImage ? (
        <FullWidthImage
          img={heroImage}
          title={title}
          subheading={subtitle}
          imgPosition="center"
          showTitle={showTitle}
        />
      ) : null}

      <section className="section section--gradient">
        <div className="container">
          <div className="content">
            <div className="">
              <DisplayContentHeader
                title={title}
                breadcrumbs={breadcrumbs}
                date={creationDate}
              />
            </div>
            {showSummary && summary ? (
              <div className="content">
                <Summary
                  summary={summary}
                  image={images.smallImage}
                  alt={title}
                />
              </div>
            ) : (
              <div className="content" style={{ display: "none" }}>
                <Summary
                  summary={summary}
                  image={images.smallImage}
                  alt={title}
                />
              </div>
            )}
            <div className="content">
              {photos &&
              photos.showGallery &&
              photos.photo &&
              photos.photo.length ? (
                <>
                  <DisplayGallery gallery={photos.photo} sort={false} />
                </>
              ) : (
                <>
                  <p>
                    We have no photos in our {title.toLowerCase()} gallery at
                    this time.
                  </p>
                </>
              )}
              {previewContent ? (
                <>
                  <br />
                  <div className="content content___gallery-page">
                    {previewContent}
                  </div>
                </>
              ) : (
                <>
                  <br />
                  <HTMLContent
                    className="content content___gallery-page"
                    content={body}
                  />
                </>
              )}
              {showComments ? (
                <div className="content">
                  <DiscussionEmbed {...disqusConfig} />
                </div>
              ) : null}
            </div>
          </div>
        </div>

        {footerImage ? (
          <FullWidthImage
            img={footerImage}
            subheading={footer}
            imgPosition="center"
            className="footer-image-text"
            showTitle={true}
          />
        ) : null}
      </section>
    </div>
  );
};

GalleryPageTemplate.propTypes = {
  helmet: PropTypes.object,
  title: PropTypes.string.isRequired,
  name: PropTypes.string,
  subtitle: PropTypes.string,
  footer: PropTypes.string,
  images: PropTypes.shape({
    hero: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    smallImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    smallImageUrl: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    footer: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  author: PropTypes.string,
  creationDate: PropTypes.string,
  sortDate: PropTypes.string,
  flags: PropTypes.shape({
    published: PropTypes.bool,
    featured: PropTypes.bool,
  }),
  options: PropTypes.shape({
    showTitle: PropTypes.bool,
    showSummary: PropTypes.bool,
    showTags: PropTypes.bool,
    showComments: PropTypes.bool,
    showAuthorSummary: PropTypes.bool,
    showPrevNext: PropTypes.bool,
    showPopular: PropTypes.bool,
    showRelated: PropTypes.bool,
  }),
  breadcrumbs: PropTypes.shape({
    parent: PropTypes.string,
    parentSlug: PropTypes.string,
    grandparent: PropTypes.string,
    grandparentSlug: PropTypes.string,
  }),
  body: PropTypes.string,
  summary: PropTypes.string,
};

const GalleryPage = ({ data }) => {
  const { markdownRemark: post } = data;
  const { authors } = data;
  const { config } = data;
  let prefix, postfix;
  let imageSrc;

  if (
    post.frontmatter.images &&
    post.frontmatter.images.hero &&
    post.frontmatter.images.hero.childImageSharp
  ) {
    imageSrc =
      post.frontmatter.images.hero.childImageSharp.gatsbyImageData.images
        .fallback.src;
  }
  if (!post.frontmatter.titles) post.frontmatter.titles = {};

  if (post.fields.slug.indexOf("/blog") !== -1) {
    prefix = "Blog >";
    postfix = "> Photo Gallery";
  } else if (post.fields.slug.indexOf("/video") !== -1) {
    prefix = "Video >";
    postfix = "> Photo Gallery";
  } else {
    prefix = "";
    postfix = "";
  }
  if (!post.frontmatter.titles) post.frontmatter.titles = {};
  if (
    post.frontmatter.titles.subtitle === "" ||
    !post.frontmatter.titles.subtitle
  ) {
    post.frontmatter.titles.subtitle = "Photo Gallery";
  }
  return (
    <Layout>
      <GalleryPageTemplate
        slug={post.fields.slug}
        title={post.frontmatter.title}
        subtitle={post.frontmatter.titles.subtitle}
        footer={post.frontmatter.titles.footer}
        images={post.frontmatter.images}
        options={post.frontmatter.options}
        breadcrumbs={post.frontmatter.breadcrumbs}
        body={post.html}
        tags={post.frontmatter.tags}
        summary={post.frontmatter.summary}
        category={post.frontmatter.category}
        helmet={
          <Seo
            title={`${prefix} ${post.frontmatter.title} ${postfix}`}
            summary={post.frontmatter.summary}
            image={imageSrc}
            article={false}
          />
        }
        authors={authors}
        config={config}
        photos={post.frontmatter.photos}
      />
    </Layout>
  );
};

GalleryPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default GalleryPage;

export const galleryPageQuery = graphql`
  query GalleryPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        titles {
          subtitle
          footer
        }
        tags
        images {
          hero {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          footer {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          smallImage {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          smallImageUrl
        }
        options {
          showTitle
          showSummary
          showComments
          showTitlesSwapped
          showDisplayContentHeader
        }
        author
        creationDate
        summary
        category
        breadcrumbs {
          parent
          parentSlug
        }
        photos {
          showGallery
          photo {
            image {
              childImageSharp {
                thumb: gatsbyImageData(
                  width: 320
                  height: 240
                  placeholder: BLURRED
                )
                full: gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            title
            summary
          }
        }
      }
    }
  }
`;
